<template>
<div class="SubmitRecharge page">
  <headers title="提交充值订单"></headers>
  <div class="scroll">
    <ul class="bank_info">
      <li>
        <div class="label">所属银行</div>
        <div class="value">充值请联系在线客服</div>
      </li>
      <li>
        <div class="label">充值姓名</div>
        <div class="value">充值请联系在线客服</div>
      </li>
      <li>
        <div class="label">银行卡号</div>
        <div class="value">充值请联系在线客服</div>
      </li>
    </ul>
    <div class="form">
      <div class="item" v-for="(item,index) in form_list" :key="index">
        <img :src="item.icon" class="icon" alt="">
        <div class="inputBox">
          <van-field
              v-model="item.value"
              name=""
              label=""
              :placeholder="item.placeholder"
              :type="item.type"
              :rules="[{ required: true, message: item.placeholder }]"
          />
        </div>
      </div>
    </div>
    <div class="tips_box">
      <div class="title">充值注意事项</div>
      <div>
        <p v-for="(item,index) in tips_list" :key="index">{{item}}</p>
      </div>
    </div>
  </div>
  <div class="btnBox">
    <van-button class="btn" round block type="info" native-type="submit" color="linear-gradient(180deg,#9eb0ff,#4969ff)" @click="submit()">提交</van-button>
  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue"
import { Toast } from "vant";
export default {
  name: "SubmitRecharge",
  components: {
    headers
  },
  data() {
    return {
      form_list: [
        {
          icon: require('/static/img/login_name.png'),
          type: 'text',
          key: 'uname',
          placeholder: '请输入充值姓名',
          value: ''
        },
        {
          icon: require('/static/img/login_phone.png'),
          type: 'number',
          key: 'account',
          placeholder: '请输入注册账户手机号',
          value: ''
        },
        {
          icon: require('/static/img/money2.png'),
          type: 'number',
          key: 'price',
          placeholder: '请确认充值金额',
          value: ''
        },
        {
          icon: require('/static/img/remark.png'),
          type: 'text',
          key: 'marker',
          placeholder: '选填备注',
          value: ''
        },
      ],
      tips_list: [
          '1、平台充值通道仅限网银，手机银行转账',
          '2、每次充值需咨询客服获取最新充值账户，未咨询造成的',
          '3、充值通道有效时间为5分钟，超时请重新咨询在线客服',
          '4、系统充值通道开放时间为10：30至22：00',
          '5、禁止向上级代理递交保证金，否则平台概不负责',
          '6、充值正常1-10分钟到账，高峰1-30分钟到账',
      ]
    }
  },
  mounted() {
  },
  methods: {
    submit() {
      let form = {}
      this.form_list.forEach(item => {
        form[item.key] = item.value
      })
      $api.bank_rc({
          ...form,
          token: this.$store.getters['getToken']
      }).then(res => {
          Toast("提交成功")
          setTimeout(()=>{
            this.$router.back()
          }, 1000)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.SubmitRecharge {
  background: #f7f7fe;
  padding-bottom: 55px;
  height: 100%;
  .scroll {
    padding-top: 10px;
    .bank_info {
        background: #fff;
        padding: 0 16px;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 55px;
          font-size: 15px;
          .value {
            width: 250px;
            text-align: left;
            font-size: 14px;
          }
        }
      }
      .form {
      margin: 15px;
      background: #fff;
      .item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebebeb;
        height: 52px;
        &:last-child {
          border: none;
        }
        .icon {
          width: 23px;
          height: 24px;
          margin-right: 15px;
          flex-shrink: 0;
        }
        .inputBox {
          flex: 1;
        }
      }
    }
      .tips_box {
          padding: 0px 15px 40px;
          text-align: left;
          .title {
            position: relative;
            margin-bottom: 12px;
            font-size: 14px;
            padding-left: 12px;
            &::after {
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 3px;
              height: 15px;
              background: linear-gradient(180deg,#ffd879,#ff902e);
            }
          }
          p {
            height: 25px;
            line-height: 25px;
            font-size: 13px;
          }
        }
  }
  .btnBox {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 15px;
  }
}
</style>